import * as React from "react";
import Layout from "../components/layout";
import { Link, graphql } from "gatsby";
import Seo from '../components/seo/seoall'

const BlogPostTemplate = ({ pageContext }) => {

  return (
    <div>
      <Layout>

        <Seo description={''} slug={pageContext.categoryname.toLowerCase() + '/' + pageContext.cont.slug} category={pageContext.categoryname + ' ' + pageContext.cont.name} />


        <div className="container-fluid p-0">
          <br />
          <br />
          <div className="row single-post m-auto justify-content-between">
            {pageContext.cont.posts.map((data, index) => {
              var excerpto = data.excerpt.substring(0,100).split(' ')
              var empt = 58 - data.H1.length
              if (empt>0){data.H1 = data.H1 + ' ' + '\u00a0'.repeat(empt*2)}

              return (
                <div className="col-12 col-md-6 col-lg-4 p-0 card-holder" key={`content_item_${index}`}>
                  <div className="card-body me-md-4 mx-2 mx-md-0 my-3 sec-color">
                    <Link to={`/${data.slug}/`} className='linkcard'>
                    <h2 className="card-title">{data.H1}</h2>
                    <p className="card-text">{excerpto.slice(0, excerpto.length - 1).join(' ')} ...</p>

                    <div className="card-link">Read More →</div>
                    </Link>
                  </div>
                </div>

              );
            })}
            <div className="col-12 col-md-6 col-lg-4 p-0 card-holder" key="added"></div>

          </div>
        </div>
      </Layout>
    </div>
  );
};

export default BlogPostTemplate;
